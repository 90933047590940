// /* eslint-disable jsx-a11y/anchor-is-valid */

// import React, { useState, useEffect } from 'react';
// import Organisation from '../assets/organisation.png';
// import post from '../assets/post.png';
// import sondage from '../assets/sondage.png';
// const ServiceHolilink = () => {
//     // Définition des données directement dans le composant
//     const items = [
//         { title: 'Organisation', description: 'Créer des organisations : Établissez des entités distinctes pour différentes parties de votre entreprise ou projet.', image: Organisation },
//         { title: 'Post', description: 'Communiquez efficacement avec vos membres en publiant des annonces, des mises à jour et des contenus importants. Partagez des informations pertinentes pour maintenir l\'engagement et l\'implication de votre communauté.', image: post },
//         { title: 'Sondage', description: 'Lancer des Sondages : Recueillez des avis et des feedbacks précieux grâce à des sondages personnalisables. Analysez les résultats pour prendre des décisions éclairées.', image: sondage },
//     ];

//     const [activeIndex, setActiveIndex] = useState(0);

//     const handleItemClick = (index) => {
//         setActiveIndex(index);
//     };

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
//         }, 30000); // Change l'élément toutes les 3 secondes

//         return () => clearInterval(interval); // Nettoyage de l'intervalle
//     }, [items.length]);

//     return (
//         <div className='flex flex-col mt-20'>
//             <div>
//                 <h1 class="mb-4 text-2xl md:text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-300 dark:text-white">Explorez Notre Réseau social <span class="bg-blue-100 text-blue-800 text-2xl font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-2">Holilink</span></h1>
//                 <p class="mb-6 text-sm md:text-lg lg:text-xl font-normal text-gray-500 lg:px-16 xl:px-48 dark:text-gray-400">Holilink vous permet de créer des organisations structurées et efficaces, adaptées à vos besoins spécifiques. Avec Holilink, vous pouvez :</p>
//                 <a href="#" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
//                     voir plus
//                     <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" fill="none" viewBox="0 0 14 10">
//                         <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
//                     </svg>
//                 </a>
//             </div>
//             <div className="flex flex-row md:ml-16 ">
//                 <ol className="basis-1/2 bg-carousel md:h-96 md:p-5 md:m-2 md:mr-5 flex items-center flex-col border-2 border-blue rounded-lg">
//                     <div className='flex items-center flex-row w-3/4 '>
//                         {items.map((item, index) => (
//                             <>
//                                 <li
//                                     key={index}
//                                     className={`flex w-full items-center justify-center text-blue-600 dark:text-blue-500 ${index === activeIndex ? "bg-blue-500 dark:bg-blue-300 text-white" : "bg-gray-100 dark:bg-gray-700 text-black"
//                                         } rounded-full h-10 w-10 lg:h-12 lg:w-12 shrink-0 cursor-pointer`}
//                                     onClick={() => handleItemClick(index)}
//                                 >
//                                     {index + 1}
//                                 </li>
//                                 {index < items.length - 1 && (
//                                     <span className="w-20 border-2 border-gray-500 border-dashed border-x-0 border-t-0 "></span>
//                                 )}

//                             </>
//                         ))}
//                     </div>
//                     <div className='mt-3 px-5  text-left '>
//                         <div className='text-white text-lg text-3xl lg:text-3xl py-2 sm:px-16 xl:px-15 font-mono dark:text-white'>
//                             {items[activeIndex].title}
//                         </div>
//                         <div className='mb-6  text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-15 dark:text-gray-400'>
//                             {items[activeIndex].description}
//                         </div>

//                     </div>


//                 </ol>
//                 <div className="basis-1/2 md:m-2 md:ml-8 flex items-center justify-center content-crl-img2">

//                     <img src={items[activeIndex].image} alt={items[activeIndex].title} className="img-objet1 fade-down-right" key={activeIndex} />

//                 </div>
//             </div>
//         </div>

//     );
// };

// export default ServiceHolilink;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Organisation from '../assets/organisation.png';
import post from '../assets/post.png';
import sondage from '../assets/sondage.png';

const ServiceHolilink = () => {
    const items = [
        { title: 'Organisation', description: 'Créer des organisations : Établissez des entités distinctes pour différentes parties de votre entreprise ou projet.', image: Organisation },
        { title: 'Post', description: 'Communiquez efficacement avec vos membres en publiant des annonces, des mises à jour et des contenus importants. Partagez des informations pertinentes pour maintenir l\'engagement et l\'implication de votre communauté.', image: post },
        { title: 'Sondage', description: 'Lancer des Sondages : Recueillez des avis et des feedbacks précieux grâce à des sondages personnalisables. Analysez les résultats pour prendre des décisions éclairées.', image: sondage },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handleItemClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 30000); // Change l'élément toutes les 30 secondes

        return () => clearInterval(interval);
    }, [items.length]);

    return (
        <div className='flex flex-col mt-20 pb-9 px-4 md:px-8 lg:px-16'>
            <div>
                <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold leading-none tracking-tight text-gray-300 dark:text-white mb-4">Explorez Notre Réseau social <span className="bg-blue-100 text-blue-800 font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">Holilink</span></h1>
                <p className="font-light text-gray-200 sm:text-xl dark:text-gray-400 mb-6">Holilink vous permet de créer des organisations structurées et efficaces, adaptées à vos besoins spécifiques. Avec Holilink, vous pouvez :</p>
                <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                    voir plus
                    <svg className="w-3.5 h-3.5 ml-2" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </a>
            </div>
            <div className="flex flex-col md:flex-row mt-8">
                <ol className="flex-1 bg-carousel p-5 border-2 border-blue rounded-lg">
                    <div className='flex items-center flex-row w-full justify-center mb-3 '>
                        {items.map((item, index) => (
                            <React.Fragment key={index}>
                                <li
                                    className={`flex items-center justify-center text-blue-600 dark:text-blue-500 ${index === activeIndex ? "bg-blue-500 dark:bg-blue-300 text-white" : "bg-gray-100 dark:bg-gray-700 text-black"
                                        } rounded-full h-10 w-10 lg:h-12 lg:w-12 cursor-pointer mx-2`}
                                    onClick={() => handleItemClick(index)}
                                >
                                    {index + 1}
                                </li>
                                {index < items.length - 1 && (
                                    <span className="w-20 border-2 border-gray-500 border-dashed border-x-0 border-t-0 "></span>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='text-left'>
                        <div className='text-white text-2xl lg:text-3xl py-2 font-mono dark:text-white'>
                            {items[activeIndex].title}
                        </div>
                        <div className='font-light text-gray-200 sm:text-xl dark:text-gray-4000'>
                            {items[activeIndex].description}
                        </div>
                    </div>
                </ol>
                <div className="flex-1 flex items-center justify-center mt-4 md:mt-0 md:ml-8">
                    <img src={items[activeIndex].image} alt={items[activeIndex].title} className="max-w-full h-auto" />
                </div>
            </div>
        </div>
    );
};

export default ServiceHolilink;