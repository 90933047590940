/* eslint-disable jsx-a11y/anchor-is-valid */
// import holicorp from '../assets/logo-site/holicorp.png';
// import holilink from '../assets/logo-site/holilink.png';
// import holibreak from '../assets/logo-site/holibreak.png';
// import holilearn from '../assets/logo-site/holilearn.png';
// import holijob from '../assets/logo-site/holijob.png';

// const Produit = () =>{
//     return (
//         <div class="flex flex-row items-center py-0 m-3 md:my-16 prdt-style">
//                 <div class="basis-1/2 text-base bg-sky-700 py-2 text-white text-animate">

//                     Des milliers d'utilisateurs font confiance à nos solutions.
//                 </div>
//                 <div class="basis-1/1">
//                     <div class="u-partners-card-wrap">
//                         <div class="u-partners-card-inline-wrap">
//                             <div class="u-partners-card">
//                                 <div class="u-partners-card-item">
//                                     <img src={holicorp} loading="lazy" className='img' alt="" />
//                                 </div>
//                                 <div class="u-partners-card-item">
//                                     <img src={holibreak} loading="lazy" className='img' alt="" />
//                                 </div>
//                                 <div class="u-partners-card-item">
//                                     <img src={holijob} loading="lazy" className='img' alt="" />
//                                 </div>
//                                 <div class="u-partners-card-item">
//                                     <img src={holilearn} loading="lazy" className='img' alt="" />
//                                 </div>
//                                 <div class="u-partners-card-item">
//                                     <img src={holilink} loading="lazy" className='img' alt="" />
//                                 </div>

//                             </div>
//                         </div>
// <div class="u-partners-card-inline-wrap">
//     <div class="u-partners-card">

//         <div class="u-partners-card-item">
//             <img src={holicorp} loading="lazy" className='img' alt="" />
//         </div>
//         <div class="u-partners-card-item">
//             <img src={holibreak} loading="lazy" className='img' alt="" />
//         </div>
//         <div class="u-partners-card-item">
//             <img src={holijob} loading="lazy" className='img' alt="" />
//         </div>
//         <div class="u-partners-card-item">
//             <img src={holilearn} loading="lazy" className='img' alt="" />
//         </div>
//         <div class="u-partners-card-item">
//             <img src={holilink} loading="lazy" className='img' alt="" />
//         </div>
//     </div>
// </div>


//                     </div>
//                 </div>
//             </div>
//     );
// }

// export default Produit;
import holicorp from '../assets/logo-site/holicorp.png';
import holilink from '../assets/logo-site/holilink.png';
import holibreak from '../assets/logo-site/holibreak.png';
import holilearn from '../assets/logo-site/holilearn.png';
import holijob from '../assets/logo-site/holijob.png';

const Produit = () => {
    return (
        <div className="flex flex-col sm:flex-row items-center py-0 m-3 md:my-16 prdt-style">
            <div className="w-full sm:basis-1/3 text-sm sm:text-base bg-sky-700 py-2 text-white text-animate">
                Des milliers d'utilisateurs font confiance à nos solutions.
            </div>
            <div className="w-full sm:basis-1/1">
                <div className="u-partners-card-wrap">
                    <div className="u-partners-card-inline-wrap">
                        <div className="u-partners-card">
                            {/* Répéter pour chaque image */}
                            <div class="u-partners-card-item">
                                <a href='https://holicorp.pro/'>
                                    <img src={holicorp} loading="lazy" className='img' alt="" />
                                </a>

                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holilearn.pro/holibreak/" >
                                    <img src={holibreak} loading="lazy" className='img' alt="" />
                                </a>

                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holijob.pro/">
                                    <img src={holijob} loading="lazy" className='img' alt="" />
                                </a>
                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holilearn.pro/">

                                    <img src={holilearn} loading="lazy" className='img' alt="" />
                                </a>
                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holilink.pro/">
                                    <img src={holilink} loading="lazy" className='img' alt="" />
                                </a>
                            </div>
                            {/* Fin de la répétition */}
                        </div>
                    </div>
                    <div className="u-partners-card-inline-wrap hidden sm:hidden lg:block">
                        <div className="u-partners-card">
                            {/* Répéter pour chaque image */}
                            <div class="u-partners-card-item">
                                <a href='https://holicorp.pro/'>
                                    <img src={holicorp} loading="lazy" className='img' alt="" />
                                </a>

                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holilearn.pro/holibreak/" >
                                    <img src={holibreak} loading="lazy" className='img' alt="" />
                                </a>

                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holijob.pro/">
                                    <img src={holijob} loading="lazy" className='img' alt="" />
                                </a>
                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holilearn.pro/">

                                    <img src={holilearn} loading="lazy" className='img' alt="" />
                                </a>
                            </div>
                            <div class="u-partners-card-item">
                                <a href="https://www.holilink.pro/">
                                    <img src={holilink} loading="lazy" className='img' alt="" />
                                </a>
                            </div>
                            {/* Fin de la répétition */}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Produit;