// import logo from './logo.svg';
import './App.css';
// import Header from './components/header';
import Accueil from './views/Accueil';
import Footer from './components/footer';

function App() {
  return (
    <div className="App scroll-smooth md:scroll-auto">
      {/* <Header /> */}
      <Accueil />
      <Footer />
    </div>
  );
}

export default App;
