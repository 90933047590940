/* eslint-disable jsx-a11y/anchor-is-valid */

// import React, { useState, useEffect } from 'react';
// import mission from '../assets/mission.png';
// import valeur from '../assets/valeur.png';
// import vision from '../assets/vision.png';
// const MissionValeurVision = () => {
//   // Définition des données directement dans le composant
//   const items = [
//     { title: 'Vision', description: 'Devenir la plateforme web de référence pour les besoins technologiques de nos clients, en offrant des solutions informatiques innovantes et fiables.', image: vision },
//     { title: 'Mission', description: 'Aider nos clients à surmonter les défis technologiques avec des services de qualité supérieure, de la consultation à la maintenance.', image: mission },
//     { title: 'Valeur', description: 'Notre engagement envers l\'excellence, la collaboration, l\'innovation, l\'intégrité et la satisfaction de nos clients, guide chaque aspect de notre travail chez Holicorp', image: valeur },
//   ];

//   const [activeIndex, setActiveIndex] = useState(0);

//   const handleItemClick = (index) => {
//     setActiveIndex(index);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
//     }, 30000); // Change l'élément toutes les 3 secondes

//     return () => clearInterval(interval); // Nettoyage de l'intervalle
//   }, [items.length]);

//   return (
//     <div className="flex flex-row md:ml-16 ">
//       <ol className="basis-1/2 bg-carousel md:h-96 md:p-5 md:m-2 md:mr-5 flex items-center flex-col border-2 border-blue rounded-lg">
//         <div className='flex items-center flex-row w-3/4 '>
//           {items.map((item, index) => (
//             <>
//               <li
//                 key={index}
//                 className={`flex w-full items-center justify-center text-blue-600 dark:text-blue-500 ${index === activeIndex ? "bg-blue-500 dark:bg-blue-300 text-white" : "bg-gray-100 dark:bg-gray-700 text-black"
//                   } rounded-full h-10 w-10 lg:h-12 lg:w-12 shrink-0 cursor-pointer`}
//                 onClick={() => handleItemClick(index)}
//               >
//                 {index + 1}
//               </li>
//               {index < items.length - 1 && (
//                 <span className="w-20 border-2 border-gray-500 border-dashed border-x-0 border-t-0 "></span>
//               )}

//             </>
//           ))}
//         </div>
//         <div className='mt-3 px-5  text-left '>
//           <div className='text-white text-lg text-3xl lg:text-3xl py-2 sm:px-16 xl:px-15 font-mono dark:text-white'>
//             {items[activeIndex].title}
//           </div>
//           <div className='mb-6  text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-15 dark:text-gray-400'>
//             {items[activeIndex].description}
//           </div>

//         </div>


//       </ol>
//       <div className="basis-1/2 md:m-2 md:ml-8 flex items-center justify-center content-crl-img">

//       <img src={items[activeIndex].image} alt={items[activeIndex].title} className="img-objet fade-down-right" key={activeIndex} />

//       </div>
//     </div>
//   );
// };

// export default MissionValeurVision;

import React, { useState, useEffect } from 'react';
import mission from '../assets/mission.png';
import valeur from '../assets/valeur.png';
import vision from '../assets/vision.png';
import engagement from '../assets/engagement.png';
import apropos from '../assets/apropos.png';
const Apropos = () => {
  // Définition des données directement dans le composant
  const items = [
    { title: 'Apropos', description: 'HoliCorp : Est une société de services informatiques et de marketing digital qui offre une gamme complète de solutions pour aider les entreprises à atteindre leurs objectifs.', image: apropos },
    { title: 'Vision', description: 'Notre vision est de devenir l Société de Services en Ingénierie Informatique de référence pour répondre aux besoins technologiques de nos clients. Nous aspirons à être reconnus comme le partenaire de confiance offrant des solutions informatiques innovantes, fiables et adaptées à chaque entreprise.', image: vision },
    { title: 'Mission', description: 'Notre mission est d’aider nos clients à surmonter les défis technologiques , Digital et numérique auxquels ils sont confrontés.', image: mission },
    { title: 'Valeur', description: 'Envers l’excellence, la collaboration, l’innovation, l’intégrité et la satisfaction de nos clients, guide chaque aspect de notre travail chez HoliCorp', image: valeur },
    { title: 'Engagement', description: 'En tant que Société de Services en Ingénierie Informatique, nous nous engageons à fournir des services de qualité supérieure, allant de la consultation et de l’analyse des besoins à la conception, au développement et à la maintenance de solutions informatiques sur mesure.', image: engagement },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 30000); // Change l'élément toutes les 3 secondes

    return () => clearInterval(interval); // Nettoyage de l'intervalle
  }, [items.length]);

  return (
    <div className='flex flex-col mt-20'>
      <div>
        <h1 class="mb-4 text-2xl md:text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-300 dark:text-white">Apropos de Holicorp</h1>
        <p class="mb-6 font-light text-gray-200 sm:text-xl dark:text-gray-400">Explorez Holicorp et découvrez notre mission variée, nos valeurs fondamentales, notre vision inspirante, et notre engagement indéfectible envers nos clients. </p>
        <a href="#" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
          voir plus
          <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </a>
      </div>
      <div className="flex flex-col md:flex-row mt-8 ">
        <ol className="flex-1 bg-carousel p-5 border-2 border-blue rounded-lg">
          <div className='flex items-center flex-row w-full justify-center mb-3 '>
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <li
                  className={`flex items-center justify-center text-blue-600 dark:text-blue-500 ${index === activeIndex ? "bg-blue-500 dark:bg-blue-300 text-white" : "bg-gray-100 dark:bg-gray-700 text-black"
                    } rounded-full h-10 w-10 lg:h-12 lg:w-12 cursor-pointer mx-2`}
                  onClick={() => handleItemClick(index)}
                >
                  {index + 1}
                </li>
                {index < items.length - 1 && (
                  <span className="w-20 border-2 border-gray-500 border-dashed border-x-0 border-t-0 "></span>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className='text-left'>
            <div className='text-white text-2xl lg:text-3xl py-2 font-mono dark:text-white'>
              {items[activeIndex].title}
            </div>
            <div className='font-light text-gray-200 sm:text-xl dark:text-gray-400'>
              {items[activeIndex].description}
            </div>
          </div>
        </ol>
        <div className="basis-1/2 md:m-2 md:ml-8 flex items-center justify-center ">

          <img src={items[activeIndex].image} alt={items[activeIndex].title} className="img-objet fade-down-right" key={activeIndex} />

        </div>
      </div>
    </div>

  );
};

export default Apropos;