/* eslint-disable jsx-a11y/anchor-is-valid */


import React, { useState, useEffect } from 'react';
import dev from '../assets/developpement web.png';
import telecom from '../assets/Telecom.png';
import marketing from '../assets/Marketing digital.png';
import social from '../assets/social manager.png';
import rh from '../assets/RH.png';
// Importez vos icônes ici. Exemple avec des placeholders :
import { FaCode, FaNetworkWired, FaDigitalTachograph, FaUserFriends, FaUsers } from 'react-icons/fa';

const Services = () => {
    const items = [
        { title: 'Développement web et mobile', description: 'Transformez votre présence en ligne avec nos services de développement web et mobile, créant des applications performantes et intuitives.', image: dev, icon: <FaCode /> },
        { title: 'Réseau et Télécom', description: 'Optimisez votre infrastructure avec nos solutions réseau et télécom avancées, assurant une communication fiable et sécurisée pour votre entreprise.', image: telecom, icon: <FaNetworkWired /> },
        { title: 'Marketing Digital', description: 'Augmentez votre visibilité en ligne avec nos stratégies de marketing digital, incluant SEO, publicités payantes et gestion des réseaux sociaux.', image: marketing, icon: <FaDigitalTachograph /> },
        { title: 'Social Manager', description: 'Engagez votre audience avec notre expertise en gestion des réseaux sociaux, créant du contenu captivant et interactif pour renforcer votre marque.', image: social, icon: <FaUserFriends /> },
        { title: 'Ressources Humaines', description: 'Renforcez votre équipe grâce à nos solutions RH, comprenant recrutement, formation et gestion des talents pour un environnement de travail optimal.', image: rh, icon: <FaUsers /> },
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const handleItemClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 10000); // Change l'élément toutes les 10 secondes

        return () => clearInterval(interval); // Nettoyage de l'intervalle
    }, [items.length]);

    return (

        <div className='flex flex-col mt-20'>
            <div>
                <h1 class="mb-4 text-2xl md:text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-300 dark:text-white">Explorez Nos Services</h1>
                <p class="mb-6 font-light text-gray-200 sm:text-xl dark:text-gray-400">Découvrez comment nos services en informatique, réseau, télécom, RH, design, et marketing peuvent transformer votre entreprise.</p>
                <a href="#" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                    voir plus
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </a>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <ol className="p-5 m-2 mr-5 flex items-center flex-col">
                        <div className='flex items-center flex-row md:flex-col w-3/4 '>
                            {items.map((item, index) => (
                                <React.Fragment key={index}>
                                    <li
                                        className={`flex w-full items-center justify-center text-blue-600 dark:text-blue-500 ${index === activeIndex ? "bg-blue-500 dark:bg-blue-300 text-white" : "bg-gray-100 dark:bg-gray-700 text-black"
                                            } rounded-full h-10 w-10 md:h-12 md:w-12 md:shrink-0 cursor-pointer mx-2`}
                                        onClick={() => handleItemClick(index)}
                                    >
                                        {item.icon}
                                    </li>
                                    {index < items.length - 1 && (
                                        <span className="w-20 border-2 border-gray-500 border-dashed border-x-0 border-t-0 md:w-0 md:h-20 md:border-2 md:border-gray-500 md:border-dashed md:border-y-2 md:border-t-0 "></span>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </ol>
                </div>
                <div className="md:col-span-2 m-2  flex items-start justify-center">
                    <div class="content-crl-img1 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-col md:max-w-xl dark:border-gray-700 dark:bg-gray-800 ">
                        <img class="object-cover w-full rounded-t-lg  md:rounded-none md:rounded-l-lg" src={items[activeIndex].image} alt={items[activeIndex].title} />
                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-xl md:text-2xl font-bold tracking-tight text-white dark:text-white">{items[activeIndex].title}</h5>
                            <p class="mb-3 font-light text-gray-200 sm:text-xl dark:text-gray-400">{items[activeIndex].description}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Services;