import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="py-4 w-full text-left text-lg font-semibold text-blue-200 dark:text-white flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className={isOpen ? 'text-blue-600' : 'text-gray-400'}>
          {isOpen ? '-' : '+'}
        </span>
      </button>
      {isOpen && <p className="pb-4 px-4 text-gray-300 dark:text-gray-400">{answer}</p>}
    </div>
  );
};
export default FAQItem;