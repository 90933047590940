import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import contact from '../assets/contact.png';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_jzj4rv1', 'template_feiq8jg', formData, 'zGCIz_EVZY9An1JGA')
            .then((result) => {
                console.log(result.text);
                alert('Message envoyé avec succès!');
            }, (error) => {
                console.log(error.text);
                alert('Erreur lors de l\'envoi du message.');
            });
    };

    return (
        <div className='flex flex-col mt-20'>
            <div>
                <h1 className="mb-4 text-2xl md:text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-300 dark:text-white">Contactez-nous</h1>
                <p className="mb-6 font-light text-gray-200 sm:text-xl dark:text-gray-400">Ou envoyez-nous un email directement à <a href="mailto:contact@example.com" className="font-medium text-indigo-600 hover:text-indigo-500">contact@holicorp.pro</a></p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <img src={contact} alt=".image" className="max-w-full h-auto" />
                    
                </div>
                <section className="bg-slate-900 shadow-inner dark:bg-gray-900">
                    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                        <form onSubmit={handleSubmit} className="space-y-8">
                            <div>
                                <label htmlFor="email" className="block text-left mb-2 text-lg font-medium text-white dark:text-gray-300">Email</label>
                                <input type="email" id="email" value={formData.email} onChange={handleChange} className="shadow-sm bg-zinc-900 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@holicorp.pro" required />
                            </div>
                            <div>
                                <label htmlFor="subject" className="block text-left mb-2 text-lg font-medium text-white dark:text-gray-300">Sujet</label>
                                <input type="text" id="subject" value={formData.subject} onChange={handleChange} className="block p-3 w-full text-sm text-white bg-zinc-900 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Dites-nous comment nous pouvons vous aider" required />
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-left mb-2 text-lg font-medium text-white dark:text-gray-400">Votre message</label>
                                <textarea id="message" rows="6" value={formData.message} onChange={handleChange} className="block p-2.5 w-full text-sm text-white bg-zinc-900 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Laissez un commentaire..."></textarea>
                            </div>
                            <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-indigo-600 sm:w-fit hover:bg-green-400 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Envoyer message</button>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ContactPage;