
import FAQItem from './FAQItem';


const FAQPage = () => {
  const faqs = [
    { question: 'Quels services propose Holicorp ?', answer: 'Holicorp propose une gamme de services incluant le développement d\'applications sur mesure, la transformation digitale, le marketing digital, la gestion de réseaux et télécoms, la vidéosurveillance et la gestion d\'accès, ainsi que des services créatifs.' },
    { question: 'Comment Holicorp aide-t-il à la transformation digitale ?', answer: 'Holicorp modernise les processus et modèles d\'affaires en adoptant des solutions technologiques innovantes pour améliorer l\'efficacité, la productivité et la compétitivité des entreprises sur le marché numérique.' },
    { question: 'Quels types de formations propose Holicorp ?', answer: 'Holicorp offre des formations sur les nouvelles technologies et logiciels, ainsi qu\'un support technique pour résoudre les problèmes informatiques courants. Cela inclut une assistance téléphonique ou en ligne pour les utilisateurs finaux.' },
    { question: 'Qu\'est-ce que le programme HoliBreak ?', answer: 'HoliBreak est un programme visant à promouvoir l\'enseignement de qualité, l\'entrepreneuriat spécifique et la recherche scientifique.' },
    { question: 'Qu\'est-ce que HoliLink ?', answer: 'HoliLink est une solution de réseau social qui permet d\'échanger des cartes de visite numériques et de gérer un portefeuille d\'opportunités, facilitant ainsi les connexions professionnelles et le réseautage.' },
    // Ajoutez plus de FAQs ici
  ];

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-5xl text-white font-bold text-center mb-6">FAQ</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQPage;