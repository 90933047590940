import React, { useEffect, useState } from "react";
/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useState } from 'react';
import '../index.css';
import logo_site from '../assets/logo-site/Black-Blue-vision-real-estate-logo-2.png'

import logo from '../assets/logo-site/banner.gif';
import AOS from 'aos';
import 'aos/dist/aos.css';
import MissionValeurVision from './Apropos';
import Produit from './Produit';
import Services from './Services';
import ServiceHolilink from "./ServiceHolilink";
import FAQPage from "./FaqPage";
import Team from "./Team";
import ContactPage from "./ContactPage";

const Accueil = () => {
    // Ajout d'un état pour gérer l'affichage du menu mobile
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [activeLink, setActiveLink] = useState("");

    // Fonction pour gérer le changement de lien actif
    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };
    useEffect(() => {
        AOS.init({
            easing: "ease-in-cubic",
            disable: "phone",
            duration: 2000,
            // anchorPlacement: "center-bottom"
        });
    }, [])
    return (
        <div className="accueil">
            <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="https://holicorp.pro/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo_site} className="h-8" alt="Holicorp Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">HoliCorp</span>
                    </a>
                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        {/* <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Get started</button> */}
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} type="button" className="text-blue-600 inline-flex p-3  hover:bg-blue-600 rounded lg:hidden ml-auto hover:text-white outline-none" aria-controls="navbar-sticky" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
                        </button>
                    </div>
                    {/* Modifier la classe ici pour afficher le menu lorsque isMenuOpen est vrai */}
                    <div className={`${isMenuOpen ? 'flex' : 'hidden'} items-center justify-between w-full md:flex md:w-auto md:order-1`} id="navbar-sticky">
                        <ul className="flex flex-col p-4 w-full  md:p-0 mt-4 font-medium  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900">
                            <li>
                                <a href="#" onClick={() => handleSetActiveLink("section1")} className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${activeLink === "section1" ? "text-blue-700" : "text-gray-900 dark:text-white"}`} aria-current="page">Accueil</a>
                            </li>
                            <li>
                                <a href="#section2" onClick={() => handleSetActiveLink("section2")} className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${activeLink === "section2" ? "text-blue-700" : "text-gray-900 dark:text-white"}`}>À propos</a>
                            </li>
                            <li>
                                <a href="#section3" onClick={() => handleSetActiveLink("section3")} className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${activeLink === "section3" ? "text-blue-700" : "text-gray-900 dark:text-white"}`}>Services</a>
                            </li>
                            <li>
                                <a href="#section7" onClick={() => handleSetActiveLink("section7")} className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${activeLink === "section7" ? "text-blue-700" : "text-gray-900 dark:text-white"}`}>Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <img src={logo} alt="..." className="" />
            <section className="" id="section1" data-aos="fade-up">
                <Produit />
            </section>

            <section className="" id="section2" data-aos="zoom-in">
                <MissionValeurVision />
            </section>

            <section className="" id="section3" data-aos="zoom-up">
                <Services />
            </section>

            <section className="holilink-stl" id="section4" data-aos="fade-down">
                <ServiceHolilink />
            </section>
            <section className="my-16" id="section5" data-aos="zoom-up">
                <Team />
            </section>
            <section className="faq-stl my-16" id="section6" data-aos="zoom-in">
                <FAQPage />
            </section>
            <section className="contact-stl mt-16" id="section7" data-aos="fade-down">
                <ContactPage />
            </section>


        </div>
    );
};

export default Accueil;